import { Typography, Grid, Card, Box, useTheme } from "@mui/material";
import React from "react";
import {
  ClientSideSingleScanAnalysisViewModel
} from "../../redux/services/view-models/ClientSideSingleScanAnalysisViewModel";
import { ScanProcessingStatusIndicator } from "./ScanProcessingStatusIndicator";
import { getFormattedDateFromString } from "../../app/utilities/dateUtilities";
import "../../style/Theme";

interface ISingleScanProcessingCardProps {
  scanAnalysisVM: ClientSideSingleScanAnalysisViewModel;
  compact?: boolean;
}

/**
 * This component displays a card on the Single Scan Processing dashboard representing
 * the state of processing on a Scan Analysis
 */
export const SingleScanProcessingCard: React.FunctionComponent<
  ISingleScanProcessingCardProps
> = (props) => {
  const theme = useTheme();

  const getStripeColor = (): string => {

    // Priority for stripe color: Error > Warning > Everything else
    if (props.scanAnalysisVM.currentStepHasError) {
      return theme.palette.error.light;
    }

    if (props.scanAnalysisVM.currentStepHasWarnings) {
      return theme.palette.warning.main;
    }

    if (props.scanAnalysisVM.canMoveToNextStep) {
      return theme.palette.success.light;
    }

    if (props.scanAnalysisVM.currentStepComplete) {
      return theme.palette.success.main;
    }

    return theme.palette.inProgress.main;
  };

  return (
    <Card variant={props.compact ? "outlined" : "elevation"}
          square={props.compact}
          data-cy="SingleScanProcessingCard"
          sx={{
            my: props.compact ? "2px":"6px",
            mx: "3px",
            backgroundColor: theme.palette.background.paper,
            borderColor: theme.palette.secondary.dark,
            display: "flex"
          }}>
      <Box data-cy="SingleScanProcessingCardStatusStripe" sx={{
        width: 4,
        backgroundColor: getStripeColor()
      }}></Box>
      <Grid container spacing={0} alignItems="flex-start">
        <React.Fragment>
          <Grid item xs={4}>
            <Typography variant="subtitle2" sx={{
              textAlign: "right",
              marginRight: "2px",
              fontSize: 12,
              color: theme.palette.primary.light,
            }}>
              Application:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2" sx={{ fontSize: 12 }}>
              {props.scanAnalysisVM.applicationTemplate.displayName}
            </Typography>
          </Grid>
        </React.Fragment>
        <Grid item xs={4}>
          <Typography variant="subtitle2" sx={{
            textAlign: "right",
            marginRight: "2px",
            fontSize: 12,
            color: theme.palette.primary.light,
          }}>
            Patient:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle2" sx={{ fontSize: 12 }}>
            {props.scanAnalysisVM.patient.patientIdentifier}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" sx={{
            textAlign: "right",
            marginRight: "2px",
            fontSize: 12,
            color: theme.palette.primary.light,
          }}>
            Exam Date:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle2" sx={{ fontSize: 12 }}>
            {getFormattedDateFromString(props.scanAnalysisVM.exam.examDate)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" sx={{
            textAlign: "right",
            marginRight: "2px",
            fontSize: 12,
            color: theme.palette.primary.light,
          }}>
            Timepoint:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" sx={{ fontSize: 12 }}>
            {props.scanAnalysisVM.timepoint.timepointName}
          </Typography>
        </Grid>

        {!props.compact && (
          <React.Fragment>
            <Grid item xs={4}>
              <Typography variant="subtitle2" sx={{
                textAlign: "right",
                marginRight: "2px",
                fontSize: 12,
                color: theme.palette.primary.light,
              }}>
                Scans:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle2" sx={{ fontSize: 12 }}>
                CT
                {props.scanAnalysisVM.functionalScanViewModel && " & PET"}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle2" sx={{
                fontSize: 8,
                color: theme.palette.secondary.dark,
                padding: "2px",
              }}>
                {props.scanAnalysisVM.scanAnalysis.id}
              </Typography>
            </Grid>
          </React.Fragment>
        )}

        <Grid item xs={2}>
          <ScanProcessingStatusIndicator
            scanAnalysisVM={props.scanAnalysisVM}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
