import React, { MutableRefObject, useRef } from "react";
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import {
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Badge,
  Container,
  Grid,
  Menu,
  MenuItem,
  Grow,
  Box
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { NavItems } from "./NavItems";
import { ApplicationState } from "../../redux/store/ConfigureStore";
import { Copyright } from "./Copyright";
import { AiqConfigurations } from "../../redux/aiqConfigurations";
import { authSlice } from "../../app/slices/authSlice";
import { CurrentUserSelector } from "../../app/selectors/authSelectors";
import { useDispatch, useSelector } from "react-redux";
import CssBaseline from '@mui/material/CssBaseline';
import { SelectedStudy } from "../../app/selectors/studySelectors";

/**
 * The shape of the props that this component expects
 */
interface MainLayoutProps {
  children?: React.ReactNode;
}

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const MainLayout: React.FunctionComponent<MainLayoutProps> = (props) => {
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
  const currentUser = useSelector((state: ApplicationState) => CurrentUserSelector(state));
  const selectedStudy = useSelector((state: ApplicationState) => SelectedStudy(state));
  const theme = useTheme();
  const [accountMenuOpen, setAccountMenuOpen] = React.useState<boolean>(false);
  const myRef: MutableRefObject<HTMLButtonElement|null> = useRef(null);
  const dispatch = useDispatch();

  const handleAccountMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAccountMenuOpen(true);
  }

  const handleAccountMenuClose = () => {
    setAccountMenuOpen(false);
  }

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  }
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  }

  React.useEffect(() => {
    if (selectedStudy) {
      document.title = selectedStudy.studyName ?? `AIQ Scan Processing Client - ${AiqConfigurations.environmentName} Environment`;
    }
  }, [selectedStudy]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        data-cy="AppBar"
        position="absolute"
        open={drawerOpen}
      >
        <Toolbar sx={{paddingRight: "24px"}}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            sx={{ marginRight: 36, ...(drawerOpen && { display: 'none' }) }} 
            size="large">
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{flexGrow: 1}}
            data-cy="AppTitle"
          >
            AIQ Scan Processing Client - {AiqConfigurations.environmentName} Environment
          </Typography>
          <div data-cy="UserName">{currentUser?.platformUserName}</div>
          <IconButton
            ref={myRef}
            onClick={handleAccountMenuOpen}
            color="inherit"
            size="large">
            <Badge color="secondary">
              <AccountCircle />
            </Badge>
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={myRef.current}
            keepMounted
            open={accountMenuOpen}
            onClose={handleAccountMenuClose}
            TransitionComponent={Grow}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={() => dispatch(authSlice.actions.logout())}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"         
        open={drawerOpen}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>{NavItems}</List>
      </Drawer>
      <Box sx={{      
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        }}>
        <Box sx={{minHeight: "64px"}} />
        <Container maxWidth={false} sx={{
            pt: 1,
            pb: 2,
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}>
          <Grid sx={{
              flexGrow: 1,
              alignContent: "baseline",
              maxHeight: "95%",            
            }} 
            container spacing={0}>
            {props.children}
          </Grid>
        </Container>
        <Copyright />
      </Box>
    </Box>
  );
}
