import { Theme, Typography, Grid, Card, CircularProgress, Container, useTheme, Box } from "@mui/material";

import React from "react";
import { getFormattedDateFromString, dateFormatWithTime } from "../../app/utilities/dateUtilities";
import { ClientSideInterscanAnalysisViewModel } from "../../redux/services/view-models/ClientSideInterscanAnalysisViewModel";
import { ScanProcessingStatusIndicator } from "./ScanProcessingStatusIndicator";
import { SingleScanProcessingCard } from "./SingleScanProcessingCard";

interface IInterscanProcessingCardProps {
  interscanAnalysisVM: ClientSideInterscanAnalysisViewModel;
}

/**
 * This component displays a card on the Interscan Processing dashboard representing
 * the state of processing on an Interscan Analysis
 */
export const InterscanProcessingCard: React.FunctionComponent<IInterscanProcessingCardProps> = (props) => {
  const theme = useTheme();

  const getStripeColor = (): string => {

    // Priority for stripe color: Error > Warning > Everything else
    if (props.interscanAnalysisVM.currentStepHasError) {
      return theme.palette.error.light;
    }

    if (props.interscanAnalysisVM.currentStepHasWarnings) {
      return theme.palette.warning.main;
    }

    if (props.interscanAnalysisVM.canMoveToNextStep) {
      return theme.palette.success.light;
    }

    if (props.interscanAnalysisVM.currentStepComplete) {
      return theme.palette.success.main;
    }

    return theme.palette.inProgress.main;
  };

  return (
    <Card data-cy="InterscanProcessingCard" sx={{
      mx: "3px",
      my: "6px",
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.secondary.dark,
      display: "flex"
    }}>
      <Box data-cy="InterscanProcessingCardStatusStripe" sx={{ width: 4, backgroundColor: getStripeColor()}}></Box>
      <Grid container spacing={0} alignItems="flex-start">
        <Grid item xs={4}>
          <Typography variant="subtitle2" sx={{
              textAlign: "right",
              marginRight: "2px",
              fontSize: 12,
              color: theme.palette.primary.light
          }}>
            Study:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle2" sx={{fontSize: 12}}>
            {props.interscanAnalysisVM.study.studyName}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" sx={{
              textAlign: "right",
              marginRight: "2px",
              fontSize: 12,
              color: theme.palette.primary.light
          }}>
            Created:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle2" sx={{fontSize: 12}}>
            {getFormattedDateFromString(props.interscanAnalysisVM.interscanAnalysis.initiatedDate, dateFormatWithTime)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {props.interscanAnalysisVM.sourceScanAnalysisGroups.map((group, groupIndex) => (
            <Box 
            key = {groupIndex}
            sx={{ 
              p: props.interscanAnalysisVM.isGrouped ? .3 : 0, 
              my: props.interscanAnalysisVM.isGrouped ? 1 : 0,
              mx: props.interscanAnalysisVM.isGrouped ? .5 : 0, 
              background: props.interscanAnalysisVM.isGrouped ? (theme) => theme.palette.secondary.light : "inherit"
            }}>
            {group.sort((a, b) => new Date(a.exam.examDate!).getTime() - new Date(b.exam.examDate!).getTime())
            .map((scanAnalysisVM) => (
              <SingleScanProcessingCard
                key={scanAnalysisVM.scanAnalysis.id!}
                scanAnalysisVM={scanAnalysisVM}
                compact
              />
            ))}
            </Box>
            ))}
          {props.interscanAnalysisVM.sourceScanAnalysisGroups.filter(group => group.length > 0).length === 0 && (
            <Container style={{ textAlign: "center" }}>
              <CircularProgress color="inherit" size={20} />
            </Container>
          )}
        </Grid>
        <Grid item xs={10}>
          <Typography variant="subtitle2" sx={{
            fontSize: 8,
            color: theme.palette.secondary.dark,
            padding: "2px"
          }}>
            {props.interscanAnalysisVM.interscanAnalysis.id}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <ScanProcessingStatusIndicator scanAnalysisVM={props.interscanAnalysisVM} />
        </Grid>
      </Grid>
    </Card>
  );
};
