import * as React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-mui";
import { Grid, Button, Box } from "@mui/material";
import { Study, StudyStatus } from "../../app/api/aiq-api";
import 'react-date-picker/dist/DatePicker.css';


/**
 * This interface defines the shape of the data this component expects for its props.
 */
interface IStudyFormProps {
  study: Study;
  isNewStudy: boolean;
  readOnly: boolean;
  studyStatuses: Array<StudyStatus>;
  existingStudyNames: Array<string>;
  onSave: (formData: IStudyFormViewModel) => void;
  onCancel: () => void;
  isSaving: boolean;
}

/**
 * This interface defines the shape of the data that the StudyForm works with
 */
export interface IStudyFormViewModel {
  startDate: Date;
  principalInvestigator: string | undefined;
  studyName: string | undefined;
  statusId: string | undefined;
  id: string | undefined;
}



/**
 * This is a dumb component that displays an editing UI for a Platform Study
 */
export class StudyFormComponent extends React.PureComponent<IStudyFormProps> {
  formik: React.RefObject<HTMLFormElement>;
  constructor(props: IStudyFormProps) {
    super(props);
    this.formik = React.createRef();
  }

  handleSubmit(data: IStudyFormViewModel) {
    this.props.onSave(data);
  }

  get loadedValues(): IStudyFormViewModel {
    return {
      studyName: this.props.study.studyName ?? "",
      id: this.props.study.id,
      statusId: this.props.studyStatuses[0].id,
      startDate: new Date(),
      principalInvestigator: "",
    } as IStudyFormViewModel;
  }

  componentDidUpdate(prevProps: IStudyFormProps) {
    // If the form went from editable to readonly without a submission, reset it
    if (!prevProps.readOnly && this.props.readOnly) {
      if (this.formik && this.formik.current) {
        this.formik.current.reset();
      }
    }
  }

  public render() {
    return (
      <React.Fragment>
        <Formik<IStudyFormViewModel>
          initialValues={this.loadedValues}
          validationSchema={Yup.object({
            studyName: Yup.string()
              // Custom rule to make sure the name isn't already in useform
              .test("name-available", "This name is not available", (value) => {
                // Bail if the name hasn't changed
                if (value === this.props.study.studyName) return true;

                const trimmedLowerValue = value?.trim().toLocaleLowerCase();
                const indexOfValue = this.props.existingStudyNames.indexOf(trimmedLowerValue ?? "");
                return indexOfValue === -1;
              })
              .matches(/^[A-Za-z0-9-_\s.]+$/, 'Field must only contain upper and lower case characters, numbers, dashes, underscores, spaces, and periods')
              .max(64, "Must be 64 characters or less")
              .required("Required"),
          })}
          onSubmit={(data, { setSubmitting }) => {
            this.handleSubmit(data);
            setSubmitting(false);
          }}
        >
          {({ submitForm, dirty, values, setFieldValue }) => (
            <Form ref={this.formik}>
              <Grid container spacing={3} sx={{ p: 2 }}>
                {/* Study Name */}
                <Grid item xs={12} sm={8}>
                  <Field
                    data-cy="StudyFormStudyName"
                    name="studyName"
                    label="Study Name"
                    fullWidth
                    autoFocus
                    variant="standard"
                    tabIndex="1"
                    component={TextField}
                    disabled={!this.props.isNewStudy || this.props.readOnly}
                  />
                </Grid>
                {!this.props.readOnly && (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        flexFlow: "row wrap",
                        "& Button": {
                          marginRight: "10px",
                        },
                      }}
                    >
                      {/* Submit button */}
                      <Button
                        data-cy="StudyFormSubmit"
                        variant="contained"
                        color="primary"
                        disabled={this.props.isSaving || !dirty}
                        onClick={submitForm}
                      >
                        {this.props.isSaving ? "Saving..." : "Save"}
                      </Button>{" "}
                      {/* Cancel button */}
                      <Button
                        data-cy="StudyFormCancel"
                        variant="contained"
                        onClick={this.props.onCancel}
                        color="secondary"
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                )}{" "}
              </Grid>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

/**
 * This is a dumb component that displays an editing UI for a Platform Study
 */
export const StudyForm = StudyFormComponent;
